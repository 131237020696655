.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
	color: #394141 !important;
	font-size: 16px;
	font-weight: 600;
	font-family: "Poppins", sans-serif;
	text-shadow: none;
	cursor: pointer;
} 

.ant-tabs-tab:hover {
	color: #2E3333;
}

.ant-tabs-ink-bar,
.ant-tabs-ink-bar-animated {
	height: 4px !important;
	background-color: #E37F66;
	border-radius: 12px;
}

.ant-tabs-tab-btn {
	outline: none;
	transition: none;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
}

.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
	border-bottom: none;
}

.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
	margin-bottom: 8px;
  }
  .ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
	/* height: 40px; */
	margin-top: 4px;
	margin-bottom: 4px;
	padding: 0 5px;
	overflow: hidden;
	line-height: 40px;
	text-overflow: ellipsis;	
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    position: absolute;
    top: 50%;
    right: 0px;
    left: auto;
    margin: 0;
    transform: translateY(-50%);
	font-size: 14px;
}

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 0px;
    padding-right: 0px;
    background-color: #ffffff;
	color: #3A4366;
	font-size: 18px;
	font-weight: 600;
	font-family: "Poppins", sans-serif;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: #ffffff;
    border-top: 0;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 15px 0px;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #d9d9d9;
}

.ant-modal {
  position: absolute;
  bottom: 0px !important;
  top: initial;
}

.ant-modal-content {
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.fade-enter {
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-appear {
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-leave {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-enter.fade-enter-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-appear.fade-appear-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  animation-name: fadeOut;
  animation-play-state: running;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ant-badge-count {
  z-index: auto;
  min-width: 15px;
  height: 15px;
  padding: 0px 5px;
  color: #18AA57;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  white-space: nowrap;
  text-align: center;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #B7B4B9;
}

.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
  z-index: -1 !important;
}